import React from "react";
import Layout from "../components/Layout";
import Container from "../components/Container";
import H1 from "../components/H1";
import { Flex, Box } from "@rebass/grid";

const NotFoundPage = () => (
  <Layout>
    <Container>
      <Flex my={7}>
        <Box>
          <H1>Pagina niet gevonden</H1>
        </Box>
      </Flex>
    </Container>
  </Layout>
);

export default NotFoundPage;
